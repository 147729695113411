<template>
  <v-container>
    <v-layout row wrap align-center="">
      <v-flex xs12 class="display-2 text-center my-5">Impressum</v-flex>
      <v-flex xs12 mt-4>
        <v-flex class="headline">Angaben gemäß §5 ECG</v-flex>
        <v-flex class="subheading ml-4">
          Barbara Liebscher
        </v-flex>
        <v-flex class="subheading ml-4 mt-2">
          Österreich
        </v-flex>
        <v-flex class="subheading ml-4 mt-2">
          3680 Persenbeug
        </v-flex>
        <v-flex class="subheading ml-4 mt-2">
          Donaufeld 7
        </v-flex>
      </v-flex>
      <v-flex xs12 mt-4>
        <v-flex class="headline">Kontakt</v-flex>
        <v-flex class="subheading ml-4 mt-2">
          0650 2030511
        </v-flex>
        <v-flex class="subheading ml-4 mt-2">
          barbara.liebscher75@gmail.com
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Impressum"
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
}
</style>
